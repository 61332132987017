<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green" @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="hbillsale"
          :items="dsbillsale.Array"
          class="elevation-1"
        >
          <template v-slot:body.append>
            <tr>
              <td colspan="6" class="text-right">รวม</td>
              <td class="text-right">{{ dsbillsale.TotalBill }}</td>
              <td class="text-right">{{ dsbillsale.Total }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hbillsale: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "Date",
          width: 100,
          style: "",
        },
        {
          text: "วันที่เบิกทั้งหมด",
          align: "left",
          sortable: true,
          value: "DateDetail",
          width: 220,
        },
        {
          text: "ชื่อสมาชิก",
          align: "left",
          sortable: true,
          value: "MemberName",
        },
        {
          text: "ชื่อสวนยาง",
          align: "left",
          sortable: true,
          value: "RubberName",
        },
        {
          text: "ผู้เบิก",
          align: "left",
          sortable: true,
          value: "Name",
          width: 180,
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "Remark",
        },
        {
          text: "บิล",
          align: "right",
          sortable: true,
          value: "Count",
          width: 100,
        },
        {
          text: "จำนวนเงิน",
          align: "right",
          sortable: true,
          value: "Total",
          width: 150,
        },
      ],
      dsbillsale: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let date = Date.now();
      date = new Date(date);

      let start =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let end =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.Select(start, end);
    });
  },
  methods: {
    Search() {
      this.Select(this.dateStart, this.dateEnd);
    },
    Select(start, end) {
      let data = {
        dateStart: start,
        dateEnd: end,
      };
      axios.post("/api/v1/select/Select/Widen", data).then((res) => {
        if (res.status == 200) {
          this.dsbillsale = res.data;
        }
      });
    },
    Prints() {
      if(this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext: "รายงานเบิก",
          hd: this.hbillsale,
          ite: this.dsbillsale.Array,
          fot: [
            { text: "รวม", cols: 7 },
            { text: this.dsbillsale.TotalBill, cols: 1 },
            { text: this. dsbillsale.Total, cols: 1 },
          ],
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
